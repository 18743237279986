import { CategoryService } from './category.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment-timezone';

@Injectable()
export class TagManagerService {
  constructor(@Inject(PLATFORM_ID) private platformId: any, private categoryService: CategoryService) {}

  public sendDataLayerEvent(data: object) {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof dataLayer.push === 'function') {
        dataLayer.push({ ...data, eventTime: this.getDataLayerFormattedTime() });
      }
    }
  }

  public sendExcerptFirstPlayingDataLayer(audioExcerpt: any, eventContext: any) {
    const shows = this.getExcerptShows(audioExcerpt);
    const hosts = this.getExcerptHosts(audioExcerpt);

    this.sendDataLayerEvent({
      event: 'Player',
      modeDiffusion: 'Rediffusion',
      showName: shows ? shows.join(', ') : '',
      showHost: hosts ? hosts.join(', ') : '',
      showExcerpt: audioExcerpt.title || '',
      actionState: 'First playing',
      mediaType: 'Audio',
      publicationDate: audioExcerpt.publishedDate || '',
      creationDate: audioExcerpt.creationDate || '',
      totalDuration: audioExcerpt.media?.duration > 0 ? audioExcerpt.media?.duration : 0,
      percentListen: 0,
      elapseTime: 0,
      currentDuration: 0,
      value: 9,
      category: this.categoryService.getCategoryDetailFromCategory(audioExcerpt.category)?.parentCategory?.slugFr,
      subCategory: this.categoryService.getCategoryDetailFromCategory(audioExcerpt.category)?.childCategory?.slugFr,
      context: eventContext || '',
    });
  }

  private getExcerptShows(audioExcerpt: any) {
    if (audioExcerpt && audioExcerpt.shows) {
      return audioExcerpt.shows.map((element: any) => element.slug);
    }
    return [];
  }

  private getExcerptHosts(audioExcerpt: any) {
    if (audioExcerpt && audioExcerpt.hosts) {
      return audioExcerpt.hosts.map((element: any) => element.firstName + ' ' + element.lastName);
    }
    return [];
  }

  private getDataLayerFormattedTime() {
    return moment()
      .tz('America/Toronto')
      .format('YYYY-MM-DD HH:mm:ss [UTC]Z');
  }
}
